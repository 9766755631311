import { graphql, Link } from 'gatsby';
import React, { FC, Fragment, useContext } from 'react';
import styled from 'styled-components';
import { fullWidthOnVertical } from '../../constants/css/fullWidthOnVertical';
import theme from '../../constants/theme';
import { CaseIntroFragment } from '../../entities/operationResults';
import { tinyCss } from '../atoms/BodyText';
import { ExternalContentLink } from '../atoms/ContentLink';
import Grid from '../atoms/Grid';
import Img from '../atoms/Img';
import Title from '../atoms/Title';
import Cell from '../molecules/Cell';
import CmsContent from '../atoms/CmsContent';
import Translate from '../Translate';
import { SiteContext } from '../../contexts/SiteContext';

export const caseIntroFragment = graphql`
    fragment CaseIntroFragment on Craft_Case {
        urlLink {
            url
            text
        }
        title
        caseIntro {
            subTitle
            text {
                content
            }
            hero {
                title
                url500: url(fit: { width: 500, quality: 100 })
                url1200: url(fit: { width: 1200, quality: 100 })
                url2000: url(fit: { width: 2000, quality: 100 })
            }
        }

        services {
            ...EntryFragment
        }
        techniques {
            techniqueTitle
            entry {
                ...EntryFragment
            }
        }
    }
`;

interface Props {
    data: CaseIntroFragment;
}

const CaseIntro: FC<Props> = ({ data }) => {
    const site = useContext(SiteContext);
    const caseIntro = data.caseIntro && data.caseIntro[0];
    if (!caseIntro) {
        return null;
    }
    const { title, services, techniques, urlLink } = data;

    const { text, subTitle } = caseIntro;
    const hero = caseIntro.hero && caseIntro.hero[0];
    return (
        <Fragment>
            <Grid columns={{ s: 18, m: 12, l: 12, xl: 10 }} marginRight={1}>
                <TextCell horizontal={{ start: 4, end: -1 }} vertical={{ start: 7, end: 25 }}>
                    <IntroText>
                        <Title variant={'large'}>
                            <strong>{title}</strong>
                        </Title>
                        <SubTitle>{subTitle}</SubTitle>
                        {text && text.content && <CmsContent html={text.content} />}
                    </IntroText>
                    <Relations>
                        <Relation>
                            <Label>
                                <Translate id="solutions" />
                            </Label>
                            <RelationWrapper>
                                {services &&
                                    services.map((service, i) =>
                                        service && (
                                            site !== 'en' ? (
                                                service.uri && (
                                                    <RelationLink
                                                        key={i}
                                                        to={site ? `/${site}/${service.uri}/` : `/${service.uri}/`}
                                                    >
                                                        {service.title}
                                                    </RelationLink>
                                                )
                                            ) : (
                                                <span>{service.title}</span>
                                            )
                                        )
                                    )}
                            </RelationWrapper>
                        </Relation>
                        <Relation>
                            <Label>
                                <Translate id="techniques" />
                            </Label>
                            <RelationWrapper>
                                {techniques &&
                                    techniques.map((technique, i) => {
                                        if (!technique) {
                                            return null;
                                        }
                                        // A technique can be either an entry or just a title
                                        if (!technique.entry || technique.entry.length === 0) {
                                            return <p key={i}>{technique.techniqueTitle}</p>;
                                        }
                                        const entry = technique.entry[0];
                                        return entry && entry.uri ? (
                                            <RelationLink
                                                to={site ? `/${site}/${entry.uri}/` : `/${entry.uri}/`}
                                                key={i}
                                            >
                                                {entry.title || technique.techniqueTitle}
                                            </RelationLink>
                                        ) : null;
                                    })}
                            </RelationWrapper>
                        </Relation>
                    </Relations>
                    {urlLink && urlLink.url && (
                        <span>
                            <ExternalContentLink target="_blank" href={urlLink.url}>
                                {urlLink.text}
                            </ExternalContentLink>
                        </span>
                    )}
                </TextCell>
            </Grid>

            <Grid columns={{ s: 20, m: 20, l: 16, xl: 16 }}>
                {hero && hero.url2000 && (
                    <HeroCell vertical={{ start: 4, end: -1 }}>
                        <Hero
                            alt={hero.title || 'Hero Image'}
                            srcSet={`
                        ${hero.url500} 500w,
                        ${hero.url1200} 1000w,
                        ${hero.url2000} 1500w,
                        `}
                            src={hero.url2000}
                            ratio={1085 / 1733}
                        />
                    </HeroCell>
                )}
            </Grid>
        </Fragment>
    );
};

export default CaseIntro;

const SubTitle = styled(Title).attrs({ variant: 'large' })`
    font-weight: 300;
    max-width: 64rem;
    margin-bottom: 0.64em;
`;
const HeroCell = styled(Cell)`
    place-items: flex-end;
    position: relative;
`;

const Hero = styled(Img)`
    ${fullWidthOnVertical}

    @media screen and (${theme.mediaQueries.vertical}) and (${theme.mediaQueries.horizontal}) {
        position: relative !important;
        left: 2.5rem !important;
    }

    @media (${theme.mediaQueries.horizontal}) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 15vw;
        z-index: 1;
    }

    > img {
        object-fit: cover;
    }
`;
const IntroText = styled.div``;

const Relations = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 5rem;
`;
const TextCell = styled(HeroCell)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const RelationLink = styled(Link)`
    text-decoration: none;
    color: ${theme.colors.black};
    font-weight: 500;

    :hover {
        text-decoration: underline;
    }
`;
const Relation = styled.div`
    :first-of-type {
        margin-right: 10rem;
    }
`;

const Label = styled(Title).attrs({ variant: 'narration' })`
    font-weight: 300;
    color: ${theme.colors.dustyGray};
`;

const RelationWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    > p,
    a {
        margin: 0 0.5rem 0 0;
        :not(:last-child) {
            ::after {
                right: -0.3em;
                content: ', ';
            }
        }
    }
    ${tinyCss}
`;
