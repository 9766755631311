import { graphql } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { CaseBlockFragment } from '../../entities/operationResults';
import BodyText from '../atoms/BodyText';
import Grid from '../atoms/Grid';
import Img from '../atoms/Img';
import Title from '../atoms/Title';
import Cell from './Cell';
import CmsContent from '../atoms/CmsContent';

export const caseBlockFragment = graphql`
    fragment CaseBlockFragment on Craft_SegmentsContentBlock {
        heading
        text {
            content
        }
        textAlignment
        image {
            ...CaseImageFragment
        }
        imageCaption {
            captionTitle
            description
        }
    }
`;

interface Props {
    data: CaseBlockFragment;
}
const CaseContentBlock: FC<Props> = ({ data }) => {
    const image = data.image && data.image[0];
    const caption = data.imageCaption && data.imageCaption[0];
    const { heading, text, textAlignment } = data;
    return (
        <Grid marginRight={3}>
            {image && image.url2000 && (
                <ImageCell
                    vertical={{ start: 4, end: 30 }}
                    horizontal={{
                        start: 1,
                        end: { s: 15, m: 18, l: 14, xl: 14 },
                    }}
                >
                    <StyledImg
                        caption={!!caption}
                        ratio={parseInt(image.height || '0', 10) / parseInt(image.width || '0', 10)}
                        srcSet={`
                            ${image.url500} 500w,
                            ${image.url1000} 1000w,
                            ${image.url1500} 1500w,
                            ${image.url2000} 2000w
                        `}
                        src={image.url2000}
                        alt={image.title || undefined}
                    />
                    {caption && (
                        <Caption>
                            <BodyText variant={'small'}>
                                <strong>{caption.captionTitle}</strong>
                            </BodyText>
                            <BodyText variant={'small'}>{caption.description}</BodyText>
                        </Caption>
                    )}
                </ImageCell>
            )}
            <TextCell
                horizontal={{
                    start: image && image.url2000 ? { s: 16, m: 19, l: 15, xl: 14 } : { s: 2, m: 2, l: 2, xl: 2 },
                    end: image && image.url2000 ? { s: 27, m: 27, l: 23, xl: 20 } : { s: 11, m: 8, l: 8, xl: 6 },
                }}
                vertical={{ start: 4, end: 27 }}
                alignText={{ top: 'flex-start', center: 'center', bottom: 'flex-end' }[textAlignment || 'center']}
            >
                <Title>{heading}</Title>
                {text && text.content && <CmsContent html={text.content} />}
            </TextCell>
        </Grid>
    );
};

export default CaseContentBlock;

const Caption = styled.div`
    padding-left: 2.5rem;
    position: relative;
    margin-top: 2.5rem;
    > p {
        margin: 0;
    }
    ::before {
        left: 0;
        top: 0;
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        background: ${theme.colors.black};
    }
`;
const StyledImg = styled(Img)<{ caption: boolean }>`
    max-height: 75vh;
    img {
        object-fit: contain;
        object-position: ${({ caption }) => (caption ? 'left' : 'unset')};
    }
`;

const TextCell = styled(Cell)<{ alignText: string }>`
    @media (${theme.mediaQueries.horizontal}) {
        align-self: ${({ alignText }) => alignText};
    }

    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
    }
`;

const ImageCell = styled(Cell).attrs({ centerChildren: true })`
    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
        margin-bottom: 5rem;
        align-self: auto;
    }
`;
