import React, { FC, useEffect, useRef } from 'react';
import Canvas, { getSize, Size } from '../atoms/Canvas';
import { TweenLite } from 'gsap';

interface Props {
    color: string;
    readyToReveal: boolean;
    onFinish: () => void;
}

const duration = 0.8;

// renders color full screen and grows a circular window from center to reveal content behind it
const AnimationRevealCircle: FC<Props> = (props) => {
    const canvasRef = useRef<HTMLCanvasElement>();
    const letsGoRef = useRef(false);
    const isCompleteRef = useRef(false);
    const radiusRef = useRef({ radius: 0 });
    const firstDrawCompletedRef = useRef(false);

    // ready to reveal effect
    useEffect(() => {
        if (props.readyToReveal) {
            // timeout to enqueue the animation until after react rendering is complete
            setTimeout(() => {
                const { width, height } = getSize(canvasRef.current!);
                letsGoRef.current = true;
                TweenLite.to(radiusRef.current, duration, {
                    radius: Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2)), // center to corner
                    onComplete: () => {
                        isCompleteRef.current = true;
                        props.onFinish();
                    },
                });
            });
        }
    }, [props.readyToReveal]);

    const draw = (
        time: DOMHighResTimeStamp,
        context: CanvasRenderingContext2D,
        { width, height }: Size,
        scheduleDraw: () => void
    ) => {
        if (isCompleteRef.current) {
            return;
        }
        context.fillStyle = props.color;

        if (!firstDrawCompletedRef.current || !letsGoRef.current) {
            // to start, draw full width/height rect
            context.fillRect(0, 0, width, height);
            context.globalCompositeOperation = 'destination-out';
            scheduleDraw();
            firstDrawCompletedRef.current = true;
            return;
        }

        const canvas = canvasRef.current;

        if (!canvas) {
            return;
        }

        const X = width / 2;
        const Y = height / 2;

        const radius = radiusRef.current.radius;

        if (radius > 0) {
            context.beginPath();
            context.arc(X, Y, radius, 0, Math.PI * 2);
            context.fill();
        }
        scheduleDraw();
    };

    return <Canvas ref={canvasRef} draw={draw} />;
};

export default AnimationRevealCircle;
