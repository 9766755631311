// a general purpose canvas component that fills the entire screen,
// respects DPI, and resizes it's internal size upon window resizing.

import React, { CanvasHTMLAttributes, Component, RefObject } from 'react';
import styled from 'styled-components';
import withForwardingRef from '../../hocs/withForwardingRef';

interface OuterProps extends CanvasHTMLAttributes<HTMLCanvasElement> {
    draw: (time: DOMHighResTimeStamp, context: CanvasRenderingContext2D, size: Size, scheduleDraw: () => void) => void;
    onResize?: () => void;
}

interface InnerProps extends OuterProps {
    forwardedRef?: RefObject<HTMLCanvasElement>;
}

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 31;

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
`;

export const getRatio = () => window.devicePixelRatio || 1;
export const getSize = (canvas: HTMLCanvasElement) => ({
    width: canvas.width / getRatio(),
    height: canvas.height / getRatio(),
});

export interface Size {
    width: number;
    height: number;
}

class Canvas extends Component<InnerProps> {
    size: Size | null = null;

    componentDidMount() {
        if (!this.props.forwardedRef) {
            // we consume the ref ourselves, so we require a ref to be passed.
            throw new Error('You MUST pass a ref to Canvas in order for it to work.');
        }

        // set correct canvas size & listen for changes
        this.setSize();
        window.addEventListener('resize', this.resize);

        this.scheduleDraw();
    }

    render() {
        const { onResize, draw, ...passedProps } = this.props;

        return (
            <Container>
                <canvas ref={this.props.forwardedRef} {...passedProps} />
            </Container>
        );
    }

    scheduleDraw = () => {
        const canvas = this.props.forwardedRef!.current;
        const context = !!canvas && canvas.getContext('2d');

        if (context && canvas) {
            if (!this.size) {
                this.size = getSize(canvas);
            }
            window.requestAnimationFrame((newTime) => this.props.draw(newTime, context, this.size!, this.scheduleDraw));
        }
    };

    resize = () => {
        this.setSize();
        if (this.props.onResize) {
            this.props.onResize();
        }
        this.scheduleDraw();
    };

    setSize() {
        const canvas = this.props.forwardedRef!.current;
        const context = !!canvas && canvas.getContext('2d');

        if (context && canvas) {
            canvas.width = window.innerWidth * getRatio();
            canvas.height = window.innerHeight * getRatio();
            context.scale(getRatio(), getRatio());

            this.size = getSize(canvas);
        }
    }
}

export default withForwardingRef(Canvas);
