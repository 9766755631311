import { graphql } from 'gatsby';
import React, { FC, Fragment, useState } from 'react';
import theme from '../constants/theme';
import { CasePageFragment, CasePageQuery, ClientQuoteFragment, CTAFragment } from '../entities/operationResults';
import { UnreachableCaseError } from '../entities/UnreachableCaseError';
import withDefaultTransition from '../hocs/withDefaultTransition';
import { Section } from './atoms/Section';
import CaseContentBlock from './molecules/CaseContentBlock';
import CTA from './molecules/CTA';
import CaseIntro from './organisms/CaseIntro';
import ClientQuote from './organisms/ClientQuote';
import Footer from './organisms/Footer';
import AnimationRevealCircle from './molecules/AnimationRevealCircle';
import { WindowLocation } from '@reach/router';
import Meta from './atoms/Meta';
import { removeTags } from '../utils/removeTags';

export const query = graphql`
    query CasePageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...CasePageFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment CasePageFragment on Craft_Case {
        id
        title
        hasCasePage
        ...CaseIntroFragment
        urlLink {
            url
        }
        quote {
            ...ClientQuoteFragment
        }
        callToAction {
            ...CTAFragment
        }
        segments {
            __typename
            ...CaseBlockFragment
        }
        directContact {
            ...DirectContactFragment
        }
        tint {
            hex
        }
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    data: CasePageQuery;
    // tslint:disable-next-line:no-any
    location: WindowLocation & { state: any };
}

const Case: FC<Props> = ({ data, location }) => {
    const entry = data.craft.entry as CasePageFragment;
    const { segments } = entry;
    const title = entry.title && entry.title;
    const quote = entry.quote && entry.quote[0];
    const mainBlock =
        entry.segments && entry.segments.find((seg) => seg && seg.__typename === 'Craft_SegmentsContentBlock');
    const footer = entry.directContact && entry.directContact[0];
    const ctaList = (entry.callToAction || []) as CTAFragment[];
    const [revealed, setRevealed] = useState(false); // reveal animation done, ready for offscreen rendering
    const isRevealed = !location.state || !location.state.animateIn || revealed;
    const meta = entry.seo && entry.seo[0];
    const caseIntro = entry.caseIntro && entry.caseIntro[0];
    const caseHero = caseIntro && caseIntro.hero;
    return (
        <Fragment>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={
                    meta && meta.seoDescription
                        ? meta.seoDescription
                        : caseIntro && caseIntro.text && removeTags(caseIntro.text.content)
                }
                image={meta && meta.seoImage ? meta.seoImage : caseHero}
            />
            {!isRevealed && (
                <AnimationRevealCircle
                    color={(entry.tint && entry.tint.hex) || '#ffffff'}
                    readyToReveal={true}
                    onFinish={() => {
                        setRevealed(true);
                    }}
                />
            )}
            <Section>
                <CaseIntro data={entry} />
            </Section>
            {isRevealed && (
                <Fragment>
                    <Section paddingLeft={4} background={theme.colors.athensGray}>
                        {quote && <ClientQuote data={quote as ClientQuoteFragment} />}
                        {mainBlock && <CaseContentBlock data={mainBlock} />}
                        {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} align="right" />}
                    </Section>
                    <Section paddingLeft={2}>
                        {segments &&
                            segments.map((segment, i) => {
                                if (!segment || segment === mainBlock) {
                                    // skip the mainblock cuz its already used in another section.
                                    return null;
                                }
                                switch (segment.__typename) {
                                    case 'Craft_SegmentsContentBlock':
                                        return <CaseContentBlock key={i} data={segment} />;
                                    default:
                                        throw new UnreachableCaseError(segment.__typename);
                                }
                            })}
                    </Section>
                    <Section variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} />}</Section>
                </Fragment>
            )}
        </Fragment>
    );
};

export default withDefaultTransition(Case);
