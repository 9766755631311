import { graphql } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ClientQuoteFragment } from '../../entities/operationResults';
import Grid from '../atoms/Grid';
import { Section } from '../atoms/Section';
import Quote from '../molecules/Quote';

export const clientQuoteFragment = graphql`
    fragment ClientQuoteFragment on Craft_Quote {
        person
        job
        quoteText
    }
`;

interface Props {
    data: ClientQuoteFragment;
}

const ClientQuote: FC<Props> = ({ data: { quoteText, job: jobTitle, person } }) => {
    return (
        <Section>
            <Grid columns={15}>
                <QuoteCell>
                    <Quote
                        variant={'large'}
                        reference={{ jobTitle: jobTitle || undefined, person: person || undefined }}
                    >
                        {quoteText}
                    </Quote>
                </QuoteCell>
            </Grid>
        </Section>
    );
};

export default ClientQuote;

const QuoteCell = styled.div`
    align-self: center;
    justify-self: center;
    grid-row: 1 / -1;
    grid-column: 4 / 13;
`;
